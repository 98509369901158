import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { GlobalStore } from './store';
import { initialize } from '@sumologic/opentelemetry-rum';

interface InitializeOptions {
  collectionSourceUrl: string;
  serviceName: string;
  deploymentEnvironment: string;
  applicationName: string;
  list_of_urls_to_receive_trace_context?:string;
}

initialize({
  collectionSourceUrl: process.env.REACT_APP_SUMOLOGIC_COLLECTION_SOURCE_URL,
  serviceName: process.env.REACT_APP_SUMOLOGIC_COLLECTION_SERVICE_NAME,
  deploymentEnvironment: process.env.REACT_APP_SUMOLOGIC_COLLECTION_DEPLOYMENT_ENVIRONMENT,
  applicationName: process.env.REACT_APP_SUMOLOGIC_COLLECTION_APPLICATION_NAME,
  samplingProbability: 1,
  collectErrors: true,
  list_of_urls_to_receive_trace_context: process.env.REACT_APP_SUMOLOGIC_COLLECTION_API_URL
} as InitializeOptions);

ReactDOM.render(
  <React.StrictMode>
    <GlobalStore>
      <Router>
        <App />
      </Router>
    </GlobalStore>
  </React.StrictMode>,
  document.getElementById('root')
);
