import React, { useEffect, useRef, useState, useContext } from 'react';
import { useNavigate } from 'react-router';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import RoleModalWrapper from '../organisms/modals/RoleModalWrapper';
import HDCard from '../hd-ui-kit/HDCard';
import HDProgressBar from '../hd-ui-kit/HDProgressBar';
import HDProgressCircle from '../hd-ui-kit/HDProgressCircle';
import HDButton from '../hd-ui-kit/HDButton';

import Api from '../Api';
import HDModal from '../hd-ui-kit/HDModal';
import { reactGAEvents, getCapabilityScore, getImpactScore, getAssesmentStatus, getDynamicCss } from '../commons/commonFunctions';
import { globalContext } from '../store';
import HDSpinner from '../hd-ui-kit/HDSpinner';

const formatAssessmentDate = (dateValue: string): string => {
  if (dateValue) {
    return `Last completed: ${dateValue}`;
  }
  return '';
};

interface ReportCardTypes {
  setLoaderHandler: any;
  gridRef: any;
  pageType?: string;
  setApiErrorHandler: any;
  setOpen: any;
  open: any;
  setShowMaintenanceModal?: any;
  showMaintenanceModal?: any;
  setRoleName?: any;
}

const ReportCard = (props: ReportCardTypes) => {
  const capabilityResInterval = useRef(null);
  const experienceResInterval = useRef(null);
  const { open, setOpen } = props;
  const { showMaintenanceModal, setShowMaintenanceModal } = props;
  const [currentRole, setCurrentRole] = useState<string>('');
  const [showRestrictModal, setShowRestrictModal] = useState<boolean>(false);
  const [showToolTipModal, setShowToolTipModal] = useState<boolean>(false);
  const [states, setAssessmentStates] = useState<any>({});
  const [toolTipModalTitle, setToolTipModalTitle] = useState<any>();
  const [toolTipModalDesc, setToolTipModalDesc] = useState<any>();
  const [toolTipModalAction, setToolTipModalAction] = useState<any>();
  const [isProfileLoaded, setProfileLoaded] = useState<boolean>(false);
  const { dispatch, globalState } = useContext(globalContext);
  const [capabilityLoader, setCapabilityLoader] = useState<boolean>(false);
  const [experienceLoader, setExperienceLoader] = useState<boolean>(false);
  const [impactCardProgressTxt, setImpactCardProgressTxt] = useState<any>();
  const [capabilityCardProgressTxt, setCapabilityCardProgressTxt] = useState<any>();
  const [impactCardProgressBtn, setImpactCardProgressBtn] = useState<boolean>(true);
  const [capabilityCardProgressBtn, setCapabilityCardProgressBtn] = useState<boolean>(true);
  const [businessRoleId, setBusinessRoleId] = useState<any>();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileNotTab = useMediaQuery(theme.breakpoints.down('sm'));
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const themeMode = true;

  const category = 'home page';
  const navigate = useNavigate();
  const apiErrorMessage = 'We are experiencing a problem calculating your scores. Please check back later.';
  let link = (
    <a href="https://heidricksupport.zendesk.com/hc/en-us" target="_blank" style={{ color: 'inherit' }}>
      contact support
    </a>
  );
  const apiErrorMessageForAssessments = <p>We are having trouble getting your assessment links, please {link} if the issue persists.</p>;
  let capabilityReportRetry = 0;
  let impactReportRetry = 0;
  const queryParameters = new URLSearchParams(window.location.search);
  const assessmentCompleteTazio = queryParameters.get('asm');

  const navigateToUrl = (url: string) => {
    if (states?.Impact?.status === 'C' && states?.Capabilities?.status === 'C') {
      navigate(url);
    } else {
      setShowRestrictModal(true);
    }
  };

  const saveAssesmentStatus = (payload: any, link?: string | undefined) => {
    if (states?.Impact?.status !== 'C' || states?.Capabilities?.status !== 'C') {
      Api.saveAssesmentStatus(payload)
        .then((res) => {
          if (res.success) {
            if (link) {
              window.open(link, '_self');
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onClickBeginAssessment = async (assessmentType: string) => {
    if ((process.env.REACT_APP_ASSESSMENT_MAINTENANCE === 'false') || process.env.REACT_APP_SPECIAL_ACCESS?.includes(globalState?.account?.email)) {
      const apiLinks = globalState?.profile?.assessments?.map((assessment: any) => assessment?.apiLink);
      if (!apiLinks || !apiLinks.length) {
        if (currentRole === '') {
          setOpen(true);
          props.setLoaderHandler(true);
        }
        return;
      }
      const apiLink = states[assessmentType].apiLink;

      const payload = {
        impact_assessment_started: assessmentType === 'Impact' ? 'yes' : 'no',
        impact_assessment_completed: 'no',
        capability_assessment_started: assessmentType === 'Capabilities' ? 'yes' : 'no',
        capability_assessment_completed: 'no',
      };
      const statusText = getStatusContent(assessmentType);
      const action = statusText === 'Begin' ? 'begin' : 'continue';
      reactGAEvents(category, action);
      saveAssesmentStatus(payload, apiLink);
    } else {
      setShowMaintenanceModal(true);
    }
  };

  const fetchProfile = async (retries = 1) => {
    const { response } = await Api.getProfile();
    if (response && response.status === 200) {
      setCapabilityLoader(false);
      setExperienceLoader(false);
      const { data } = response;
      const dataResult = data?.data?.[0] ? data?.data?.[0] : data?.data;
      if (Object.keys(dataResult || {}).length > 0) {
        dispatch({ type: 'SET_PROFILE', payload: dataResult });
        props.setLoaderHandler(false);
        if (dataResult?.assessments?.[0]?.status === 'C') {
          setExperienceLoader(true);
        }
        if (dataResult?.assessments?.[1]?.status === 'C') {
          setCapabilityLoader(true);
        }
        setProfileLoaded(true);
        if (!dataResult?.assessments?.length || (dataResult?.assessments?.[0]?.status !== 'C' && dataResult?.assessments?.[1]?.status !== 'C')) {
          props.setLoaderHandler(false);
        }
      } else {
        setProfileLoaded(true);
      }
    }
  };

  useEffect(() => {
    if (globalState?.profile) {
      const dataResult = globalState?.profile;
      setCurrentRole(dataResult?.businessRole?.name ?? '');
      if (dataResult?.assessments?.length > 0) {
        const assessments: any = dataResult?.assessments;
        let assessmentArr: any = [];
        if (assessments) {
          assessments.forEach((data: any) => {
            assessmentArr[data.name] = { ...data };
          });
        }

        const impactId = assessmentArr.Impact.uuid;
        const capabilityId = assessmentArr.Capabilities.uuid;
        const roleId = dataResult?.roleProfileId;

        //Check if we have capability report data in context
        if (Object.keys(globalState?.capabilityReport?.results).length === 0 && globalState?.assessmentStatus?.cas === 'yes') {
          // Need to get each assessments results if completed
          if (assessmentArr.Capabilities.status === 'C') {
            getCapabilityResults(capabilityId, roleId,  assessmentArr, cardCompletedTxt, true);
          } else {
            getCapabilityResults(capabilityId, roleId, assessmentArr, cardCompletedTxt, true);
            // or set interval to check for results
            setCapabilityResultInterval(capabilityId, roleId, assessmentArr, cardCompletedTxt, false);
          }
        }

        //Check if we have capability report data in context
        if (Object.keys(globalState?.impactReport?.reportData).length === 0 && globalState?.assessmentStatus?.ias === 'yes') {
          if (assessmentArr.Impact.status === 'C') {
            getExperienceResults(impactId, roleId,  assessmentArr, cardCompletedTxt, true);
          } else {
            getExperienceResults(impactId, roleId, assessmentArr, cardCompletedTxt, true);
            // or set interval to check for results
            setExperienceResultInterval(impactId, roleId, assessmentArr, cardCompletedTxt, false);
          }
        }

        setAssessmentStates(assessmentArr);
      } else {
        props.setApiErrorHandler(apiErrorMessageForAssessments);
        props.setLoaderHandler(false);
        generateAssessmentLinks(dataResult?.roleProfileId);
      }
    } else if (isProfileLoaded) {
      props.setLoaderHandler(false);
      setCapabilityLoader(false);
      setExperienceLoader(false);
    }
  }, [isProfileLoaded, globalState?.assessmentStatus]);

  const getAssessmentStatusData = async () => {
    const assesmentStatusData: any = await getAssesmentStatus();
    dispatch({ type: 'SET_ASSESSMENT_STATUS', payload: assesmentStatusData });
  };

  const inProgressTxtStyle = {
    fontWeight: 600,
    color: '#66FCFC',
    margin: 0,
    padding: 0,
  };

  const cardProgressTxt = <p style={inProgressTxtStyle}>Your assessment is in progress. Please complete your assessment to see results.</p>;
  const cardCompletedTxt = (
    <p style={inProgressTxtStyle}>
      Once your assessment is completed, it may take a few minutes to score and retrieve your results.
      <br /> Please refresh the page to check for results.
    </p>
  );
  const cardInProgressTxt = assessmentCompleteTazio === 'c' ? cardCompletedTxt : cardProgressTxt;
  useEffect(() => {
    // Need to get profile data to determine assessment status
    (!globalState?.profile) ? fetchProfile() : props?.setLoaderHandler(false);
    (globalState && globalState?.assessmentStatus && Object.keys(globalState?.assessmentStatus)?.length === 0) ? getAssessmentStatusData() : props?.setLoaderHandler(false);

    //Set Cards Text
    setImpactCardProgressTxt(cardInProgressTxt);
    setCapabilityCardProgressTxt(cardInProgressTxt);
  }, []);

  useEffect(() => {
    //Generate Assessment Reports
    if ((experienceLoader || capabilityLoader) && (globalState?.assessmentStatus && Object.keys(globalState?.assessmentStatus)?.length > 0)) {
      const assessments: any = globalState?.profile?.assessments;
      let assessmentArr: any = [];
      if (assessments) {
        assessments.forEach((data: any) => {
          assessmentArr[data.name] = { ...data };
        });
        const impactId = assessmentArr?.Impact?.uuid;
        const capabilityId = assessmentArr?.Capabilities?.uuid;
        const impactRepGenerated = globalState?.assessmentStatus?.otherDetails?.is_impact_report_generated;
        const capabilityRepGenerated = globalState?.assessmentStatus?.otherDetails?.is_capability_report_generated;
        let payload = {};
        let updateGlobal;
        if ((globalState?.assessmentStatus?.iac === 'yes' || states?.Impact?.status === 'C') && impactRepGenerated === false) {
          payload = { report_name: 'hfl_impact', assessment_id: impactId };
          updateGlobal = { is_impact_report_generated: true };
        }

        if ((globalState?.assessmentStatus?.cac === 'yes' || states?.Capabilities?.status === 'C') && capabilityRepGenerated === false) {
          payload = { report_name: 'hfl_capabilities', assessment_id: capabilityId };
          updateGlobal = { is_capability_report_generated: true };
        }
        if ((impactRepGenerated === false || capabilityRepGenerated === false) && Object.keys(payload).length > 0) {
          Api.generateAssessmentReports(payload).then((res) => {
            console.log('assess rep generate', res);
          });
          const statusArray = {
            ...globalState?.assessmentStatus,
            otherDetails: { ...updateGlobal },
          };
          dispatch({ type: 'SET_ASSESSMENT_STATUS', payload: statusArray });
        }
      }
    }
  }, [experienceLoader, capabilityLoader, states]);

  const getAssessmentCardStyle = (assessmentType: string) => {
    const basicStyle = {
      boxShadow: 'none',
      textAlign: 'left',
      height: '100%',
      background: '#002136',
    };

    if (states && Object.keys(states).length) {
      if (assessmentType === 'Impact' && states?.Impact?.status === 'C') return basicStyle;
      if (assessmentType === 'Capabilities' && states?.Capabilities?.status === 'C') return basicStyle;
    }

    if (assessmentType === 'Impact' && globalState?.assessmentStatus?.ias === 'yes') return basicStyle;
    if (assessmentType === 'Capabilities' && globalState?.assessmentStatus?.cas === 'yes') return basicStyle;

    return {
      ...basicStyle,
    };
  };

  const getCapabilityResults = async (assessmentId: string, roleId: string,  assessmentStates: any, cardCompletedTxt: any, hideLoader: boolean = false) => {
    setCapabilityLoader(true);
    capabilityReportRetry = capabilityReportRetry + 1;
    const capabilityReportData = await getCapabilityScore(assessmentId, roleId, globalState.profile, navigate);
    if (capabilityReportData != 'fail') {
      if (capabilityReportData?.message) {
        if (assessmentStates && assessmentStates.length > 0 && assessmentStates?.Capabilities?.status === 'C') {
          props.setApiErrorHandler(apiErrorMessage);
        }
        setCapabilityLoader(false);
        props.setLoaderHandler(false);
        if (capabilityReportRetry > 2) {
          clearInterval(capabilityResInterval.current);
        }
        setCapabilityCardProgressTxt(cardCompletedTxt);
        setCapabilityCardProgressBtn(false);
      } else {
        dispatch({ type: 'SET_CAPABILITY_REPORT', payload: capabilityReportData });
        clearInterval(capabilityResInterval.current);
        if (hideLoader) {
          props.setLoaderHandler(false);
        }
        setCapabilityLoader(false);
        if (globalState?.assessmentStatus.cac !== 'yes') {
          const payload = {
            impact_assessment_started: 'no',
            impact_assessment_completed: 'no',
            capability_assessment_started: 'yes',
            capability_assessment_completed: 'yes',
          };
          saveAssesmentStatus(payload);
          const statusArray = {
            ...globalState?.assessmentStatus,
            cas: 'yes',
            cac: 'yes',
          };
          dispatch({ type: 'SET_ASSESSMENT_STATUS', payload: statusArray });
        }

        if (states?.Capabilities?.status != 'C') {
          setAssessmentStates((state: any) => ({
            ...state,
            ['Capabilities']: { ...state.Capabilities, status: 'C' },
          }));
        }
        props.setApiErrorHandler('');
      }
    } else {
      if (capabilityReportRetry > 2) {
        clearInterval(capabilityResInterval.current);
      }
      props.setApiErrorHandler(apiErrorMessage);
      props.setLoaderHandler(false);
      setCapabilityLoader(false);
      setCapabilityCardProgressTxt(cardCompletedTxt);
      setCapabilityCardProgressBtn(false);
    }
  };

  const setCapabilityResultInterval = (assessmentId: string, roleId: string,  assessmentStates: any, cardCompletedTxt: any, hideLoader: boolean = false) => {
    capabilityResInterval.current = setInterval(() => {
      getCapabilityResults(assessmentId, roleId, assessmentStates, cardCompletedTxt, hideLoader);
    }, 30000);
  };

  const getExperienceResults = async (assessmentId: string, roleId: string,  assessmentStates: any, cardCompletedTxt: any, hideLoader: boolean = false) => {
    setExperienceLoader(true);
    impactReportRetry = impactReportRetry + 1;
    const impactReportData = await getImpactScore(roleId, assessmentId, globalState.profile, navigate);
    if (impactReportData != 'fail') {
      if (impactReportData === 'errorMessage') {
        if (assessmentStates && assessmentStates.length > 0 && assessmentStates?.Impact?.status === 'C') {
          props.setApiErrorHandler(apiErrorMessage);
        }
        props.setLoaderHandler(false);
        setExperienceLoader(false);
        if (impactReportRetry > 2) {
          clearInterval(experienceResInterval.current);
        }
        setImpactCardProgressTxt(cardCompletedTxt);
        setImpactCardProgressBtn(false);
      } else {
        dispatch({ type: 'SET_IMPACT_REPORT', payload: impactReportData });
        clearInterval(experienceResInterval.current);
        if (hideLoader) {
          props.setLoaderHandler(false);
        }
        setExperienceLoader(false);

        if (globalState?.assessmentStatus.iac !== 'yes') {
          const payload = {
            impact_assessment_started: 'yes',
            impact_assessment_completed: 'yes',
            capability_assessment_started: 'no',
            capability_assessment_completed: 'no',
          };
          saveAssesmentStatus(payload);
          const statusArray = {
            ...globalState?.assessmentStatus,
            ias: 'yes',
            iac: 'yes',
          };
          dispatch({ type: 'SET_ASSESSMENT_STATUS', payload: statusArray });
        }

        if (states?.Impact?.status != 'C') {
          setAssessmentStates((state: any) => ({
            ...state,
            ['Impact']: { ...state.Impact, status: 'C' },
          }));
        }
        props.setApiErrorHandler('');
      }
    } else {
      props.setApiErrorHandler(apiErrorMessage);
      props.setLoaderHandler(false);
      setExperienceLoader(false);
      setImpactCardProgressTxt(cardCompletedTxt);
      setImpactCardProgressBtn(false);
      if (impactReportRetry > 2) {
        clearInterval(experienceResInterval.current);
      }
    }
  };

  const setExperienceResultInterval = (assessmentId: string, roleId: string, assessmentStates: any, cardCompletedTxt: any, hideLoader: boolean = false) => {
    experienceResInterval.current = setInterval(() => {
      getExperienceResults(assessmentId, roleId, assessmentStates, cardCompletedTxt, hideLoader);
    }, 30000);
  };

  const getProgressStatus = (assessmentType: string) => {
    if (states && Object.keys(states).length) {
      const currentBtnState = states[assessmentType]['status'];
      if (currentBtnState === 'P') return 'started';
      if (currentBtnState === 'C') return 'completed';
      if (assessmentType === 'Impact' && globalState?.assessmentStatus?.ias === 'yes') return 'started';
      if (assessmentType === 'Capabilities' && globalState?.assessmentStatus?.cas === 'yes') return 'started';
    }
    return 'notStarted';
  };

  const getProgressColor = (assessmentType: string) => {
    return '#FFFFFF';
  };

  const getAssessmentBtnStyle = (assessmentType: string) => {
    const basicStyle = {
      fontWeight: 600,
      fontSize: '15px',
      fontStyle: 'normal',
      background: '#F1F1F1',
      color: '#002136',
      lineHeight: '22px',
      marginTop: isMobile ? '16px' : '0',
      textWrap: 'nowrap'
    };

    return {
      ...basicStyle,
      '&:hover': {
        background: '#F1F1F1',
        color: '#002136',
        textDecoration: 'none',
      },
      '&:focus': {
        borderRadius: '100px',
        color: '#002136',
        background: '#F1F1F1 !important',
        border: '1px solid #334255',
      },
      '&:focus:before': {
        content: '""',
        height: '89%',
        width: '96%',
        position: 'absolute',
        borderRadius: 'inherit',
        background: '#F1F1F1',
        zIndex: '-1',
      },
    };
  };

  const getAssessmentTitleStyle = (assessmentType: string) => {
    const basicStyle = {
      marginLeft: '10px',
      fontSize: '1.25rem',
      fontWeight: 500,
      fontFamily: 'Aventa',
      color: '#FFFFFF',
      bottom: (isMobile) ? '5px' : '0px',
      position: (isMobile) ? 'relative' : 'unset'
    };

    if (states && Object.keys(states).length) {
      if (assessmentType === 'Impact' && states?.Impact?.status === 'C') return basicStyle;
      if (assessmentType === 'Capabilities' && states?.Capabilities?.status === 'C') return basicStyle;
    }

    if (assessmentType === 'Impact' && globalState?.assessmentStatus?.ias === 'yes') return basicStyle;
    if (assessmentType === 'Capabilities' && globalState?.assessmentStatus?.cas === 'yes') return basicStyle;

    return {
      ...basicStyle,
    };
  };

  const getAssessmentFooterStyle = (assessmentType: string) => {
    const basicStyle = {
      fontFamily: 'Avenir',
      fontWeight: 300,
      fontSize: '16px',
      color: '#FFFFFF',
    };

    if (states && Object.keys(states).length) {
      if (assessmentType === 'Impact' && states?.Impact?.status === 'C') return basicStyle;
      if (assessmentType === 'Capabilities' && states?.Capabilities?.status === 'C') return basicStyle;
    }

    if (assessmentType === 'Impact' && globalState?.assessmentStatus?.ias === 'yes') return basicStyle;
    if (assessmentType === 'Capabilities' && globalState?.assessmentStatus?.cas === 'yes') return basicStyle;

    return {
      ...basicStyle,
    };
  };

  const getStatusContent = (assessmentType: string) => {
    if (states && Object.keys(states).length) {
      const currentBtnState = states[assessmentType]['status'];
      if (currentBtnState === 'P') return 'Continue';
      if (currentBtnState === 'C') return 'View Report';

      if (assessmentType === 'Impact' && globalState?.assessmentStatus && Object.keys(globalState?.assessmentStatus).length && globalState?.assessmentStatus.ias === 'yes') return 'Continue';
      if (assessmentType === 'Capabilities' && globalState?.assessmentStatus && Object.keys(globalState?.assessmentStatus).length && globalState?.assessmentStatus.cas === 'yes') return 'Continue';
    }
    return 'Begin';
  };

  const toolTipModalDes = (reportType: string) => {
    const bodyStyle = {
      fontFamily: 'Avenir',
      fontSize: '16px',
      fontWeight: 300,
      lineHeight: '24px',
    };

    if (reportType === 'leadershipCapability') {
      return (
        <Box sx={bodyStyle}>
          The Heidrick Capabilities Report provides insight into your core leadership competencies and how they compare to the relevant targets for this role.
          <br />
          Leadership capability refers to the leadership competencies determining a leader's success in mobilizing resources, driving execution across the workforce, transforming organizations, and acting and adapting with agility.
          <br />
          <br />
          Heidrick & Struggles categorizes leadership capability into this simple leadership competency model of mobilize, execute, transform, and agility (META).
          <br />
          <br />
          Underpinning these 4 key themes, your capabilities results on 11 Drive Factors are reported.
        </Box>
      );
    } else {
      return (
        <Box sx={bodyStyle}>
          The Business Impact Report provides insight into your achievement and experiences and how you compare to the relevant targets for this role.
          <br />
          <br />
          Business Impact requirements vary from role to role, but encompass two key areas:
          <ul>
            <li>General organizational impact themes, such as financial management, organizational strategy, and people & performance leadership</li>
            <li>Function-specific impact themes, that reflect the specific areas of expertise, achievement, and experience required of leaders in your target role</li>
          </ul>
        </Box>
      );
    }
  };

  const toolTipModalAct = (reportType: string) => {
    if (reportType === 'leadershipCapability') {
      return (
        <>
          <HDButton
            onClick={() => window.open('https://www.heidrick.com/en/insights/leadership-development/developing-future-ready-leaders', '_blank', 'noopener,noreferrer')}
            variant="contained"
            text={'Read more'}
            endIcon={<ArrowForwardIcon sx={{ color: themeMode ? '#18768C' : '#66FCFC' }} />}
            sx={btnStyleModal}
            showAnimation={true}
          />
          <HDButton onClick={() => setShowToolTipModal(false)} variant="outlined" text={'OK'} />
        </>
      );
    } else {
      return <HDButton onClick={() => setShowToolTipModal(false)} variant="contained" text={'OK'} endIcon={<ArrowForwardIcon sx={{ color: themeMode ? '#18768C' : '#66FCFC' }} />} sx={btnStyleModal} showAnimation={true} />;
    }
  };

  useEffect(() => {
    if (businessRoleId) {
      generateAssessmentLinks(businessRoleId);
      props.setRoleName(businessRoleId?.name);
      setCurrentRole(businessRoleId?.name);
    }
  }, [businessRoleId]);

  const generateAssessmentLinks = (businessRoleId: any) => {
    setCapabilityLoader(true);
    setExperienceLoader(true);
    let payload = {
      businessRoleID: (businessRoleId?.id) ? businessRoleId?.id : businessRoleId
    };
    Api.generateAssessmentLinks(payload).then((res: any) => {
      if (res?.success) {
        fetchProfile();
        setProfileLoaded(false);
      }
    });
  };

  const getDescriptionHeight = (percentage1: any, percentage2: any, isMobile: any) => {
    if (!percentage1) {
      if (isMobile) {
        return 'auto';
      } else {
        if (percentage2) {
          return '99px';
        } else {
          return '70px';
        }
      }
    } else {
      return 'auto';
    }
  };


  const wrapStyling = {
    flexGrow: 1,
    alignItems: 'center',
    display: 'flex',
    justifyContent: isMobileNotTab ? 'space-between' : 'unset',
  };

  const footerStyle = {
    display: 'flex',
    marginTop: '16px',
    justifyContent: 'space-between',
  };

  const btnStyleModal = {
    '&:focus': {
      backgroundColor: '#00566A',
    },
  };

  const progressBarTxtStyle = {
    height: (isMobile) ? 'auto' : '55px',
  };

  const titleWrap = {
    display: 'flex',
    alignItems: (isMobile) ? 'baseline' : 'center',
  };

  const smallTextStyle = {
    color: '#FFFFFF',
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '16px',
    lineHeight: '24px',
    marginTop: '10px',
  };

  const gridXS = props.pageType === 'MyCareer' ? 12 : 8;
  const gridMD = props.pageType === 'MyCareer' ? 12 : 6;
  const gridLG = props.pageType === 'MyCareer' ? 12 : 6;

  const cardInBeginTxtBusiness = 'Evaluate the impact you\'ve made over the course of your career by reflecting on your track record, achievements and growth opportunities';
  const cardInBeginTxtLeadership = 'Evaluate how you can mobilize, execute, transform with agility and understand the key leadership behaviors and skills to develop to achieve your goals';
  const modalTitle = 'Please complete both assessments to access your reports';
  const modalDescription = `You have yet to complete the ${states?.Impact?.status !== 'C' ? 'Business Impact Assessment' : 'Heidrick Capabilities Assessment'}`;
  const maintenanceModalTitle = 'Undergoing Maintenance';
  const maintenanceModalDescription = 'We are currently undergoing maintenance to enhance your experience. Please try again tomorrow. We appreciate your patience.';

  return (
    <>
      <Grid ref={props.gridRef} container spacing={2} pb={4} sx={{ paddingBottom: isMobile ? '15px' : '35px' }}>
        <Grid item xs={gridXS} md={gridMD} lg={gridLG} sx={{ minWidth: isMobile && '100%' }}>
          <HDCard sx={getAssessmentCardStyle('Impact')}>
            <Box sx={wrapStyling}>
              <Box sx={titleWrap}>
                <HDProgressCircle status={getProgressStatus('Impact')} color={getProgressColor('Impact')} completedColor='#2CDADA' completedIconColor='#66FCFC' />
                <Typography variant="h5" sx={getAssessmentTitleStyle('Impact')}>
                  Business Impact {globalState?.impactReport?.otherDetails?.percentage ? 'Report' : 'Assessment'}
                </Typography>
              </Box>
            </Box>
            {globalState?.impactReport?.otherDetails?.percentage ? (
              <Box>
                <Typography variant="h5" sx={smallTextStyle}>
                  Evaluate the impact you've made in your career by reflecting on your track record, achievements and growth opportunities
                </Typography>
              </Box>
            ) : (
              <></>
            )}

            {globalState?.impactReport?.otherDetails?.percentage ? (
              <Box>
                <Box sx={{ height: isMobile ? '15px' : '30px' }}>&nbsp;</Box>
                <Divider sx={{ borderColor: '#667A86' }} />
                {/* <Box sx={{ height: isMobile ? '28px' : '22px' }}>&nbsp;</Box> */}
              </Box>
            ) : (
              <> </>
            )}
            {experienceLoader ? (
              <Box sx={{ height: isMobile ? '150px' : '133px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                {' '}
                <HDSpinner color={'#12ABAB'} size={''} buttonColor={false} />
                {(businessRoleId) && <Typography sx={{ color: '#DF43B6' }}>We are fetching your assessment links</Typography>}
              </Box>
            ) : (
              <>
                {globalState?.impactReport?.otherDetails?.percentage ? (
                  <Box>
                    <Box>&nbsp;</Box>
                    <HDProgressBar
                      withoutTheme={true}
                      benchMark={50}
                      progressDescription={`You meet ~${globalState?.impactReport?.otherDetails?.percentage}%~ of ${globalState?.profile?.businessRole?.name} experience target.`}
                      progress={globalState?.impactReport?.otherDetails?.percentage}
                      titleStyle={progressBarTxtStyle}
                    />
                  </Box>
                ) : (
                  <Box>
                    <Box sx={{ height: getDynamicCss(isMobile, '28px', isLarge, '113px', '88px')}}>&nbsp;</Box>
                    <Divider sx={{ borderColor: '#667A86' }} />
                    {/* <Box sx={{ height: isMobile ? '28px' : '22px' }}>&nbsp;</Box> */}
                  </Box>
                )}

                <Box sx={{ ...footerStyle, flexDirection: (globalState?.impactReport?.otherDetails?.percentage && !isMobile) ? 'row' : 'column', alignItems: (globalState?.impactReport?.otherDetails?.percentage && !isMobile) ? 'center' : 'flex-start' }}>
                  <Box sx={{
                    width: (globalState?.impactReport?.otherDetails?.percentage && !isMobile) ? '60%' : '100%',
                    height: getDescriptionHeight(globalState?.impactReport?.otherDetails?.percentage, globalState?.capabilityReport?.percentage, isMobile),
                    marginBottom: (globalState?.impactReport?.otherDetails?.percentage) ? '0px' : '16px'
                  }}
                  >
                    <Typography sx={getAssessmentFooterStyle('Impact')}>
                      {globalState?.impactReport?.otherDetails?.percentage
                        ? formatAssessmentDate(states?.Impact?.completedOn)
                        : globalState?.assessmentStatus?.ias === 'yes'
                          ? globalState?.assessmentStatus?.iac !== 'yes' && states?.Impact?.status != 'C'
                            ? states?.Impact?.status === 'Submitted'
                              ? cardCompletedTxt
                              : cardInProgressTxt
                            : impactCardProgressTxt
                          : cardInBeginTxtBusiness}
                    </Typography>
                  </Box>
                  <Box>
                    {states?.Impact?.status != 'Submitted' ? (
                      <>
                        {states?.Impact?.status != 'C' ? (
                          <HDButton
                            endIcon={<ArrowForwardIcon sx={{ color: '#18768C' }} />}
                            onClick={
                              states?.Impact?.status != 'C'
                                ? () => onClickBeginAssessment('Impact')
                                : states && Object.keys(states).length === 0
                                  ? () => onClickBeginAssessment('Impact')
                                  : () => {
                                    reactGAEvents(category, 'view-report');
                                    navigateToUrl('/experience/overview');
                                  }
                            }
                            text={getStatusContent('Impact')}
                            sx={getAssessmentBtnStyle('Impact')}
                            showAnimation={true}
                            variant={states?.Impact?.status === 'C' ? 'text' : 'contained'}
                          />
                        ) : states?.Impact?.status === 'C' && globalState?.assessmentStatus?.iac === 'yes' && impactCardProgressBtn ? (

                          <HDButton
                            endIcon={<ArrowForwardIcon sx={{ color: '#18768C' }} />}
                            onClick={
                              states?.Impact?.status != 'C'
                                ? () => onClickBeginAssessment('Impact')
                                : states && Object.keys(states).length === 0
                                  ? () => onClickBeginAssessment('Impact')
                                  : () => {
                                    reactGAEvents(category, 'view-report');
                                    navigateToUrl('/experience/overview');
                                  }
                            }
                            text={getStatusContent('Impact')}
                            sx={getAssessmentBtnStyle('Impact')}
                            showAnimation={true}
                            variant={'contained'}
                          />
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </Box>
                </Box>
              </>
            )}
          </HDCard>
        </Grid>
        {props.pageType === 'MyCareer' && <br />}
        <Grid item xs={gridXS} md={gridMD} lg={gridLG} sx={{ minWidth: isMobile && '100%' }}>
          <HDCard sx={getAssessmentCardStyle('Capabilities')}>
            <Box sx={wrapStyling}>
              <Box sx={titleWrap}>
                <HDProgressCircle status={getProgressStatus('Capabilities')} color={getProgressColor('Capabilities')} completedColor='#2CDADA' completedIconColor='#66FCFC' />
                <Typography variant="h5" sx={getAssessmentTitleStyle('Capabilities')}>
                  Heidrick Capabilities {globalState?.capabilityReport?.percentage ? 'Report' : 'Assessment'}
                </Typography>
              </Box>
            </Box>

            {globalState?.capabilityReport?.percentage ? (
              <Box>
                <Typography variant="h5" sx={smallTextStyle}>
                  Assess the competencies that drive effective leadership behaviors and understand where to focus to achieve your goals
                </Typography>
              </Box>
            ) : (
              <></>
            )}

            {globalState?.capabilityReport?.percentage ? (
              <Box>
                <Box sx={{ height: isMobile ? '15px' : '30px' }}>&nbsp;</Box>
                <Divider sx={{ borderColor: '#667A86' }} />
                {/* <Box sx={{ height: isMobile ? '28px' : '22px' }}>&nbsp;</Box> */}
              </Box>
            ) : (
              <> </>
            )}

            {capabilityLoader ? (
              <Box sx={{ height: isMobile ? '150px' : '133px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                {' '}
                <HDSpinner color={'#12ABAB'} size={''} buttonColor={false} />
                {(businessRoleId) && <Typography sx={{ color: '#DF43B6' }}>We are fetching your assessment links</Typography>}
              </Box>
            ) : (
              <>
                {globalState?.capabilityReport?.percentage ? (
                  <Box>
                    <Box>&nbsp;</Box>
                    <HDProgressBar withoutTheme={true} benchMark={50} progressDescription={`You meet ~${globalState?.capabilityReport?.percentage}%~ of ${globalState?.profile?.businessRole?.name} skills target.`} progress={globalState?.capabilityReport?.percentage} titleStyle={progressBarTxtStyle} />
                  </Box>
                ) : (
                  <Box>
                    <Box sx={{ height: getDynamicCss(isMobile, '28px', isLarge, '113px', '88px') }}>&nbsp;</Box>
                    <Divider sx={{ borderColor: '#667A86' }} />
                    {/* <Box sx={{ height: isMobile ? '28px' : '22px' }}>&nbsp;</Box> */}
                  </Box>
                )}

                <Box sx={{ ...footerStyle, flexDirection: (globalState?.capabilityReport?.percentage && !isMobile) ? 'row' : 'column', alignItems: (globalState?.capabilityReport?.percentage && !isMobile) ? 'center' : 'flex-start' }}>
                  <Box sx={{
                    width: (globalState?.capabilityReport?.percentage && !isMobile) ? '60%' : '100%',
                    height: getDescriptionHeight(globalState?.capabilityReport?.percentage, globalState?.impactReport?.otherDetails?.percentage, isMobile),
                    marginBottom: (globalState?.capabilityReport?.percentage) ? '0px' : '16px'
                  }}
                  >
                    <Typography sx={getAssessmentFooterStyle('Capabilities')}>
                      {globalState?.capabilityReport?.percentage
                        ? formatAssessmentDate(states?.Capabilities?.completedOn)
                        : (globalState?.assessmentStatus?.cas === 'yes')
                          ? (globalState?.assessmentStatus?.cac !== 'yes' && states?.Capabilities?.status != 'C')
                            ? (states?.Capabilities?.status === 'Submitted')
                              ? cardCompletedTxt
                              : cardInProgressTxt
                            : capabilityCardProgressTxt
                          : cardInBeginTxtLeadership}
                    </Typography>
                  </Box>
                  <Box>
                    {states?.Capabilities?.status != 'Submitted' ? (
                      <>
                        {states?.Capabilities?.status != 'C' ? (
                          <HDButton
                            endIcon={<ArrowForwardIcon sx={{ color: '#18768C' }} />}
                            onClick={
                              states?.Capabilities?.status != 'C'
                                ? () => onClickBeginAssessment('Capabilities')
                                : states && Object.keys(states).length === 0
                                  ? () => onClickBeginAssessment('Capabilities')
                                  : () => {
                                    reactGAEvents(category, 'view-report');
                                    navigateToUrl('/leadership-skills/overview');
                                  }
                            }
                            text={getStatusContent('Capabilities')}
                            sx={getAssessmentBtnStyle('Capabilities')}
                            variant={states?.Capabilities?.status === 'C' ? 'text' : 'contained'}
                            showAnimation={true}
                          />
                        ) : states?.Capabilities?.status === 'C' && globalState?.assessmentStatus?.cac === 'yes' && capabilityCardProgressBtn ? (

                          <HDButton
                            endIcon={<ArrowForwardIcon sx={{ color: '#18768C' }} />}
                            onClick={
                              states?.Capabilities?.status != 'C'
                                ? () => onClickBeginAssessment('Capabilities')
                                : states && Object.keys(states).length === 0
                                  ? () => onClickBeginAssessment('Capabilities')
                                  : () => {
                                    reactGAEvents(category, 'view-report');
                                    navigateToUrl('/leadership-skills/overview');
                                  }
                            }
                            text={getStatusContent('Capabilities')}
                            sx={getAssessmentBtnStyle('Capabilities')}
                            variant={'contained'}
                            showAnimation={true}
                          />
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </Box>
                </Box>
              </>
            )}
          </HDCard>
        </Grid>
      </Grid>
      {open &&
        <RoleModalWrapper
          open={open}
          setOpen={setOpen}
          setLoader={props.setLoaderHandler}
          setBusinessRoleId={setBusinessRoleId}
        />
      }
      <HDModal
        open={showRestrictModal}
        handleModal={() => setShowRestrictModal(false)}
        title={modalTitle}
        description={modalDescription}
        actions={<HDButton onClick={() => setShowRestrictModal(false)} variant="contained" text={'OK'} endIcon={<ArrowForwardIcon />} sx={btnStyleModal} showAnimation={true} />}
      />

      <HDModal
        open={showMaintenanceModal}
        handleModal={() => setShowMaintenanceModal(false)}
        title={maintenanceModalTitle}
        description={maintenanceModalDescription}
        actions={<HDButton onClick={() => setShowMaintenanceModal(false)} variant="contained" text={'OK'} endIcon={<ArrowForwardIcon />} sx={btnStyleModal} showAnimation={true} />}
      />

      <HDModal sx={{ '& .MuiDialog-paper': { maxWidth: '60%' } }} open={showToolTipModal} handleModal={() => setShowToolTipModal(false)} title={toolTipModalTitle} description={toolTipModalDesc} actions={toolTipModalAction} />
    </>
  );
};

export default ReportCard;
